$bg-primary1: #033261;
$bg-primary2: #003975;
$bg-primary3: #8acb48;
$bg-secondary1: #0367c3;
$bg-secondary2: #148bff;
$bg-secondary3: #f47b20;

$primary: #033261;
$danger: #ff0000;
$separador: #ff00006e;
$success: #8acb48;
$default: #033261;
$outlined: #31567b;


:export {
  bg-primary1: $bg-primary1;
  bg-primary2: $bg-primary2;
  bg-primary3: $bg-primary3;
  bg-secondary1: $bg-secondary1;
  bg-secondary2: $bg-secondary2;
  bg-secondary3: $bg-secondary3;
  danger: $danger;
  success: $success;
  default: $default;
  outlined: $outlined;
}