.contenedorBanner {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.imagenFondo {
  width: 100%;
  height: 100%;
  min-height: 70vh;
  object-fit: cover;
}

.bienvenidaHB {
  position: absolute;
  left: 10%;
  bottom: 35%;
  color: #fff;
  /* font-size: 3rem; */
  font-weight: bold;
  line-height: 100%;
}

.titulo-bienvenido {
  //   font-family: Objectivity-Bold !important;
  font-family: Objectivity-Regular;
  //   font-size: 2.5rem !important;
  font-weight: 700;
  //   margin: 10px 0 0;
}

.planSalud {
  position: absolute;
  right: 0;
  top: 50px;
  width: 10rem;
  // background: rgb(5, 41, 79);
  // background: linear-gradient(0deg,#3CCEEB 0%,#0062C1 25%,#034F9A 50%,#02366B 75%,#05294F 100%);//linear-gradient(0deg,rgba(60, 206, 235, 1) 0%,rgba(18, 138, 203, 1) 23%,rgba(0, 98, 193, 1) 49%,rgba(3, 79, 154, 1) 70%,rgba(2, 54, 107, 1) 85%,rgba(5, 41, 79, 1) 100%);//linear-gradient(0deg, rgba(5, 41, 79, 1) 0%, rgba(2, 54, 107, 1) 23%, rgba(3, 79, 154, 1) 49%, rgba(0, 98, 193, 1) 70%, rgba(18, 138, 203, 1) 85%, rgba(60, 206, 235, 1) 100%);
  background: rgb(5, 41, 79);
  background: linear-gradient(
    180deg,
    rgba(5, 41, 79, 0.8) 0%,
    rgba(3, 79, 154, 0.8) 34%,
    rgba(60, 206, 235, 0.8) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 15px 0px;
  border-top-left-radius: 90px;
  border-bottom-left-radius: 90px;
}

.planSalud span {
  background-color: #fff;
  color: #0d3e91;
  border-radius: 25px;
  font-weight: bold;
  padding: 3px 10px;
  margin-top: 5px;
  cursor: pointer;
  font-size: 0.8rem;
}

.atencionps {
  min-height: 70px;
  background: white;
  //background: white(0deg, rgba(7, 56, 107, 1) 0%, rgba(7, 85, 161, 1) 27%, rgba(14, 101, 177, 1) 48%, rgba(85, 204, 227, 1) 100%);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-size: 1rem;
  padding: 0px 20px 5px 20px;
}

.iconosAtencion {
  width: 1rem;
}

.contenedorNovedades {
  position: absolute;
  right: 0px;
  top: 45%;
  color: #fff;
}
#novedadestextPS {
  color: white;
  font-size: small;
  font-family: Objectivity-Light;
}
.novedadesCabPS {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  background: #99ca3e;
  // background: linear-gradient(to top, #9fc850, #a6d153, #adda57, #b5e35a, #bcec5e);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.novedadesDetPS {
  padding-top: 10px;
  padding-bottom: 0px;
  padding-left: 30px;
  padding-right: 30px;
  background: #9fc850;
  background: linear-gradient(
    to top,
    #9fc850,
    #a6d153,
    #adda57,
    #b5e35a,
    #bcec5e
  );
  border-top-left-radius: 45px;
  border-bottom-left-radius: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 991.98px) {
  .bienvenidaHB-movil {
    position: absolute;
    left: 10%;
    top: 10%;
    color: #fff;
    /* font-size: 3rem; */
    font-weight: bold;
    line-height: 100%;
  }

  .contenedorNovedades-movil {
    position: absolute;
    right: 0px;
    top: 75px;
    color: #fff;
    margin-top: 40px;
  }

  .atencionps {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .bienvenidaHB-pc {
    position: absolute;
    left: 10%;
    bottom: 35%;
    color: #fff;
    font-size: 3.5rem !important;
    font-weight: bold;
    line-height: 100%;
  }

  .contenedorNovedades-pc {
    position: absolute;
    right: 0px;
    top: 45%;
    color: #fff;
  }
}

/* lg */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .contenedorGrupoMenu-lg {
    position: absolute;
    bottom: 30px;
    width: 100%;
  }
}

/* xl */
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .contenedorGrupoMenu-xl {
    position: absolute;
    bottom: 30px;
    width: 100%;
  }
}

/* xxl */
@media (min-width: 1400px) {
  .contenedorGrupoMenu-xxl {
    position: absolute;
    bottom: 40px;
    width: 100%;
  }
}
