.contenedorMenuSecundario {
    height: 60px;
    background-color: #004990;
    position: relative;
    display: flex;    
    flex-direction: row;
    transition: 1s;    
}
.logo{    
    flex: 1;    
    padding: 5px 0px 5px 50px;
    height: 0px;
}

.itemMenu{
    color:rgb(242 242 242);
    font-family: Assistant-Medium ;
    font-size: 1.2rem;
}



.itemMenuB{
    color: rgb(242 242 242);
    border-bottom: 2px solid #1c4dc4;        
}

.itemMenuC{
    color:rgb(242 242 242);
    padding-bottom: 10px;
    font-size: 26px;
}

.navbar-light .navbar-nav .nav-link{
    color: rgb(242 242 242);
}

.navbar-light .navbar-nav .nav-link:hover{
    color: rgb(242 242 242);
}

.navbar-light .navbar-nav .nav-link:focus{
    color: rgb(242 242 242);
}

.dropdown-menu {
    background-color: #004990;
    padding: 10px;
}

.dropdown-item:hover{
    background-color: #004990;
    color: rgb(242 242 242);
}