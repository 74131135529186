/* MV */
@media (max-width: 991.98px) {
  .container-dejanos-consulta {
    padding: 20px !important;
  }

  .img-card {
    display: none;
  }

  .title_mobiles {
    padding-bottom: 20px !important;
  }

  .text_mobile {
    padding-top: 20px !important;
  }
}

/* PC*/
@media (min-width: 992px) {
  .container-dejanos-consulta {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.bg-image2 {
  // background-image: url("../../../assets/img/hb/bg-contacto.jpg");
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100vh;
  // width: 50vw;
  // object-fit: cover;
}

.bg-image-text2 {
  text-align: center;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  width: 100%;
}

.transbox2 {
  /* margin: 30px; */
  background-image: linear-gradient(0deg,
      rgba(57, 93, 132, 0.5),
      rgba(2, 10, 20, 0.5));
  /* background: rgba(57,93,132,.5); */
  /* color: white; */
  padding: 4%;
}

.section_contacto_titulo-hb {
  font-family: Assistant-Bold;
  font-size: 40px;
  color: #05294f;
}

.form-contacto-hb {
  font-family: Assistant-Bold;
}

.subtitulo-hb {
  font-family: Assistant-SemiBold;
  color: #05294f;
  font-size: 21px;
}

.container-dejanos-consulta {
  padding: 20px !important;
}

.section_contacto_titulo {
  color: #05294f;
  font-family: Assistant-Bold;
  font-size: 30px;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.section_contacto p {
  color: #05294f;
  font-family: Assistant-Medium;
  font-size: 20px !important;
  line-height: 25px;
}

.section_contacto h4 {
  color: #05294f;
  font-family: Assistant-Bold;
  font-size: 20px;
}

// .section_contacto label {
//     color: #05294F;
//     font-family: "Assistant-VariableFont_wght";
//     font-size: 19px;
// }

select {
  // background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAANCAYAAACtpZ5jAAAABHNCSVQICAgIfAhkiAAAAMJJREFUOE+l08sNwjAQBNAZN0COHOmAQAdUQAvphFACFRDa4AIVQE5IdEEDeHGCIozwD8dXW09j7SxRVqVSWGuNHdrmgTFnsC7NlmpZ1QQ3ItJqwSob71DiRLJ4alnQJC4UeSYxz8YtVAQHfd1X7H8+BnegHfmGc3EP+g3/iwfQXzgVj6BuOIYnoH7YhwOzoVLD9H21/wzP9cJui5a7EFNTzUkMDSd2t6UrUt/T2IKGE1u4WaCjSXozmxlF0xLHonnuXwbzps6EGdpXAAAAAElFTkSuQmCC);
  // background-position: calc(100% - 20px) 12px, calc(100% - 20px) 14px, 100% 0;
  // background-repeat: no-repeat;
  // -webkit-appearance: none;
  // -moz-appearance: none;

  /* Arrow */
  appearance: none;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAANCAYAAACtpZ5jAAAABHNCSVQICAgIfAhkiAAAAMJJREFUOE+l08sNwjAQBNAZN0COHOmAQAdUQAvphFACFRDa4AIVQE5IdEEDeHGCIozwD8dXW09j7SxRVqVSWGuNHdrmgTFnsC7NlmpZ1QQ3ItJqwSob71DiRLJ4alnQJC4UeSYxz8YtVAQHfd1X7H8+BnegHfmGc3EP+g3/iwfQXzgVj6BuOIYnoH7YhwOzoVLD9H21/wzP9cJui5a7EFNTzUkMDSd2t6UrUt/T2IKGE1u4WaCjSXozmxlF0xLHonnuXwbzps6EGdpXAAAAAElFTkSuQmCC");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
}

.section_contacto_form_titulo {
  color: #05294f;
  font-family: Assistant-Bold;
  font-size: 30px;
}

.btn_section_contacto {
  color: white;
  background: #05294f;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 3px;
  padding-bottom: 3px;
  border: 1px solid #05294f;
}

// .section_contacto_form label {
//     font-size: 16px;
//     font-family: Assistant-Medium;
// }

.section_contacto_form input {
  border: 1px solid #05294f;
  box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
}

.section_contacto_form select {
  border: 1px solid #05294f;
  box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
}

.section_contacto_form textarea {
  border: 1px solid #05294f;
  box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
}

.shadow-blue {
  -webkit-box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
  box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
}

.shadow-external {
  -webkit-box-shadow: 0px 2px 15px -2px rgba(0, 0, 0, 0.28) !important;
  box-shadow: 0px 2px 15px -2px rgba(0, 0, 0, 0.28) !important;
}

.table-responsive {
  font-family: Assistant-Medium;
}

// #botCaptcha   {
//     //transform: scale(0.71);
//    // transform-origin: 0 0;
//    }