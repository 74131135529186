.button-active{

}

.button-not-active{
    background-color: rgb(239, 239, 239);
}

.button-not-active:hover{
    background-color: rgb(248, 248, 248);
    color: #14385e
}