.header-proteccion {
  border-bottom: 0px !important;
  padding-right: 35px !important;
  padding: 0px !important;
  padding-left: 20px !important;
  padding-right: 30px !important;
  padding-top: 5px !important;
}

.body-proteccion {
  padding: 0px !important;
}

.contrasenaSubTit {
  padding-right: 20px !important;
}

.barraMargin {
  margin-right: 20px !important;
  margin-left: 20px !important;
}

.titulo-facturas-Fac {
  color: #05294f;
  padding-left: 20px;
  padding-top: 60px;
  font-family: "Objectivity-Medium";
  font-size: 35px;
}

// .politica-proteccion{
//     color:#05294F;
//     padding: 0px 20px;

// }

// .politica-proteccion p{
//     text-align: justify;
//     font-family: Objectivity-Regular;

// }

// .politica-proteccion h5{
//     font-family: Objectivity-Regular;
//     color: #004990;
//     font-weight: 600;
// }

// .btn-close{
//     padding-top: 10px!important;
// }

.section-invoice-information {
  display: flex;
  margin-right: 5px;
  flex-direction: row;
}

.image-information {
  padding-right: 15px;
}

.section-consult {
  margin-left: 10px;
}

.answer-Fac {
  font-family: "Objectivity-Light";
  color: #004990;
}

.question-Fac {
  font-family: "Objectivity-Medium";
  color: #05294f;
}
