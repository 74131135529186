// CARGAR ARCHIVO
.custom-load-icon{
    // transform: translate(100%,50%);
    background-color: #05294F;   
    position: absolute;
    border-top-left-radius: 20px;    
    border-bottom-left-radius: 20px;
    border: 1px solid #6286ac;
    min-height: 4.5vh;    
    padding-left: 2vh;
    padding-right: 2vh;
    color:white;    
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-bottom: 4px;
}

.custom-load-icon span{
    margin-left: 20px;
}

.custom-load-icon input{
    padding-left: 110px;
}

.input-icons i {
    position: absolute;
}
  
.input-icons {    
    margin-bottom: 10px;        
}

.error-file
{
    color: red !important;
}

.round-label
{
    border-radius: 20px;
    width: 50%;
    border: 1px solid #6286ac;
    min-height: 4.5vh;
    color: #224d7c;
    padding-left: 30px;
    padding-right: 2vh;
    padding-bottom: 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
// FIN CARGAR ARCHIVO

@media only screen and (max-width: 1200px){

    .round-label{
        min-height: 6vh;
    }

    .custom-load-icon{
        // transform: translate(100%,50%);
        min-height: 6vh;
    }
}

