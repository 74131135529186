.header-proteccion{
    border-bottom: 0px!important;
    padding-right: 35px!important;   
    padding: 0px!important;
    padding-left: 20px!important;
    padding-right: 30px!important;
    padding-top: 5px!important;
}

.body-proteccion{
    padding: 0px!important;
}

.titulo-editar-familiar{
    color: #004990;
    padding-left: 20px;  
    padding-top: 60px; 
    font-family:  "Assistant-Regular";
    font-weight: bolder;
    font-size: 30px;   
    font-weight: 700;
    align-self: center;
    margin-bottom: 15px;
}





// .politica-proteccion{
//     color:#05294F;
//     padding: 0px 20px; 
    
// }

// .politica-proteccion p{
//     text-align: justify;
//     font-family: Assistant-Regular;

// }

// .politica-proteccion h5{
//     font-family: Assistant-Regular;
//     color: #004990;
//     font-weight: 600;
// }

.barra-editar-familiar{
    height: 3px;
    background: red;
    opacity: 1;
    border-radius: 10px;
    margin-bottom: 20px;
}

// .btn-close{
//     padding-top: 0px!important;
// }


.section-edit-information{
    display: flex;
    flex-direction: column;
    margin-right: 5px;    
}

.image-information{
    padding-right: 10px;
}

.section-consult{
    margin-left: 10px;
}

.answer{
font-family: "Assistant-Regular";
color: #004990;
font-weight: 600;
}

.question{
font-family: "Assistant-Medium";
color: #004990;
font-weight: 600;
}

.header-familygroup-information{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom:0px;
}

.header-familygroup-information p{
    margin-left:20px;
    margin-right: 20px;
}

// .btn-close
// {
//     align-self: baseline;
// }

.custom-header{
    border-bottom: 0px;
}

.pl-20{
    margin-left:20px;    
}

.circulo {
    width: 25px;
    height: 25px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    border: solid 2px red; 
    color:red;
    text-align: center;   
    font-family:"Assistant-Bold";
    margin-bottom: 15px;
}

.mb-70
{
    margin-bottom: 40px;
}