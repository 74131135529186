.hb-style p{
    font-family: "Assistant-Medium" !important;
}

.hb-style h1{
    font-family: "Assistant-Medium" !important;
}

.hb-style .button-continuar{
    font-family: "Assistant-Regular" !important;
}

.pshb-style .button-continuar{
    font-family: "Objectivity-Regular" !important;
}