$blueColor: rgb(3, 50, 97);
$redColor: rgb(255, 23, 23);

#headerContent {
  display: flex;
  justify-content: space-between;
}

.tarjeta--pshb
{
  // border-left: 20px solid #8acb48 !important;
  // background-color: #fff !important;
}

#sideRight {
  display: flex;
  align-items: center;
}

.footer-pshb {
  background-image: url("../../../assets/img/pshb/Backgrounds/bg-green.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: bottom;
  height: 105px;
  width: 100%;
}
.footer-pshb-2 {
  background-image: url("../../../assets/img/pshb/Backgrounds/bg-green.png");

  height: 100px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: bottom;
}

#titleStudy {
  max-width: 200px;
  width: 100%;
  margin-right: 15px;
  font-family: "Assistant-SemiBold";
}

// .shadow-blue {
//     -webkit-box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
//     box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
// }

// .shadow-red {
//     -webkit-box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
//     box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
// }

#user {
  width: 100vw;
  max-width: 300px;
  background-color: $blueColor;
}

#user option {
  background-color: $blueColor;
}

.input-icons i {
  position: absolute;
}

.input-icons {
  margin-bottom: 10px;
}

.icon {
  padding: 10px;
  color: green;
  min-width: 50px;
}

.input-field {
  padding: 2px 35px;
  border-radius: 20px;
  border: 1px solid #224d7c;
  background-color: $blueColor;
  color: white;
  font-family: "Assistant-Regular";
}

.input-field:focus {
  outline: none;
}

.i-25 {
  width: 25px;
  margin-right: 5px;
  cursor: pointer;
}

.customIcon {
  transform: translate(100%, 50%);
  color: rgb(1, 101, 144);
  position: absolute;
}

#estudio {
  background-color: $redColor;
  border-color: $redColor;
  color: white;
}

#findUserName {
  display: flex;
}

#filterServices {
  max-width: 400px;
  width: 100%;
}

// .separador-result{
//     border-top: 3px solid rgb(171 195 219);
//     margin-bottom: 10px;
// }

.col-years {
  display: flex;
  justify-content: end;
}

#paginate {
  display: flex;
  justify-content: end;
}

#tableResults {
  color: #033261;
}

.section_pshb_titulo {
  color: #05294f;
  font-family: "Objectivity-Medium";
  font-size: 30px;
}

.information-circle {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.information-triangle {
  width: 0;
  height: 0;
  border-left: 25px solid #f0ad4e;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  // transform: translateX(-30px);
}

#header-invoice {
  display: flex;
  justify-content: space-between;
}

#icon-information {
  height: 40px;
  width: auto;
  cursor: pointer;
}

#header-add-family-title {
  display: flex;
  flex-direction: column;
}

.header-add-family {
  height: 120px;
  border-bottom: 2px solid red;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width:992px){
  #header-add-family-title {
    margin-top:100px;
  }
}

#header-add-family-separator {
  border-top: 1px solid red;
  min-height: 1px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.family-add-buttons {
  display: flex;
  justify-content: center;
}

// .hb-title
// {
//     color:rgb(20,54,90);
//     font-family: "Assistant-SemiBold";
//     font-size: 30px;
// }

// .hb-subtitle
// {
//     color:rgb(20,54,90);
//     font-family: "Assistant-Medium";
//     font-size: 20px;
//     line-height: 25px;
// }

.hb-label {
  color: rgb(20, 54, 90);
  font-family: "Assistant-Regular";
  font-size: 18px;
  line-height: 25px;
}

#family-add-container {
  padding: 50px 25px;
}

.body-add-family {
  height: 120px;
}

.w-100 {
  width: 100%;
}

.opacity {
  opacity: 0.2;
}

.customIcon-family-group {
  // transform: translate(100%,50%);
  background-color: rgb(1, 101, 144);
  position: absolute;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 1px solid #6286ac;
  min-height: 4.5vh;
  padding-left: 2vh;
  padding-right: 2vh;
  color: white;
}

.customIcon-family-group span {
  margin-left: 5px;
}

.customIcon-family-group input {
  padding-left: 110px;
}

section {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: space-between;
  width: 100%;
}
