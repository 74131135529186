.drawer{
    background: linear-gradient(0deg, #05294F 20%, #02366B 40%, #034F9A 60%, #0062C1 80%, #3CCEEB 100%);  
}

.drawer-name{
    padding: 0px 15px 0px 15px;
}

.separador-principal-pshb
{
    border-top: 2px solid #c5d8eb;
    min-height: 1px;
    width: 100%;    
    align-self: center;
    margin-top: 10px;
}

.titulo-enlace-pshb{
    color:white;
    cursor: pointer;
    // margin-left: 12px;
    gap: 5px;    
}

.subtitulo-enlace-pshb{
    color:white;
    cursor: pointer;
}


.titulo-enlace-secundario-pshb{
    color:white;
    cursor: pointer;
    // margin-left: 12px;
    gap: 5px;     
    // width: calc(100% - 24px);
    // margin-right: 12px;
    display: flex;
    align-items: center;   
    font-size: .6rem; 
    margin-top: 10px;
    padding-bottom: 5px;
    margin-bottom: 3px;
}

.icono-titulo
{
    height: 19px;
    width: 19px;
}

.separador-titulo{
    border-bottom: 1px solid #c5d8eb;
    min-height: 1px;
    // width: 50%;
    // margin-top: 20px;
    // margin-bottom: 10px;
    // align-self: center;
}

.enlace-drawer-pshb{
    padding-left: 25px;
    padding-right: 15px;
    padding-top: 0px;
    line-height: 30px;
}

.grupo-enlaces-pshb{
    padding-bottom: 20px;
}