.red-asterisk span {
  color: red;
}

.grandientNavbar {
  display: flex;
  align-items: center;
  height: 33px;
  background: transparent
    linear-gradient(180deg, #05294f 0%, #004990 11%, #3fb2c9 100%, #6cb2f1 100%)
    0% 0% no-repeat padding-box;
  justify-content: space-between;
}

.gradientNavbarHB {
  display: flex;
  align-items: center;
  height: 33px;
  background: transparent
    linear-gradient(180deg, #05294f 0%, #004990 11%, #3fb2c9 100%, #6cb2f1 100%)
    0% 0% no-repeat padding-box;
  justify-content: end;
}

.grandientNavbar_element {
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-items: center;
}

.grandientNavbar_searchInput {
  height: 28px;
  margin-right: 5px;
  margin-left: 5px;
}

.grandientNavbar_separator {
  border-right: 1px solid white;
  height: 28px;
  margin-left: 5px;
  margin-right: 5px;
}

.grandientNavbar_idioma {
  color: #ffffff;
  font-size: 12px;
  line-height: 2.3;
  align-items: center;
  display: flex;
}

.grandientNavbar_idioma_icon {
  height: 20px;
  margin-left: 5px;
}

.phoneText {
  font-size: 16px;
  color: white;
  margin-right: auto;
  margin-left: 5px;
  font-family: Objectivity-Regular;
}

.iconWP {
  margin-left: 10px;
  margin-right: 10px;
}

/* Navbar */
.customNavbar {
  padding-left: 24px;
  padding-right: 5px;
  box-shadow: 2px 7px 8px -1px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 2px 7px 8px -1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 2px 7px 8px -1px rgba(0, 0, 0, 0.15);
}

.customNavbar_brand {
  display: flex;
  // height: 70px;
}

.customNavbar_brand_logo {
  /* width: 7em; */
  width: 125px;
}

.customNavbar_brand_logo-ps {
  /* width: 7em; */
  // width: 125px;
  object-fit: cover;
}

.customNavbar_brand_logo_separator {
  // border-right: 2px solid #b6b8bb;
  margin-left: 50px;
  margin-right: 10px;
  height: 52px;
}

.customNavbar_brand_button {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}

.customNavbar_brand_button_home {
  width: 24px;
}

.nav-mega {
  width: 100%;
}

a#basic-nav-dropdown {
  text-align: center;
}

/* .nav-mega .dropdown { 
    position: static; 
} */

/*nav-mega .dropdown .dropdown-menu.show {
    /* max-width: 1140px; */
/* min-width: auto;
    width: 100%;
    margin: auto;
    right: 0;
    padding: 0;
    border-color: #ccc;
    height: 361px; */

/* .nav-mega .dropdown .dropdown-menu.show li{
    padding: 20px;
}

.nav-mega .dropdown .media-list {
    padding: 10px; font-size: 13px;
    
} */

/* .nav-mega .dropdown-toggle:after{
    display:none
} */

/* .dropdown-menu.show:before {
    content: "";
    background: url('../../assets/img/onda-pacientes.png');
    background-repeat: no;
    width: 100%;
    height: 120px;
    position: absolute;
    top: 100%;
    
} */

/* .nav-mega .dropdown .dropdown-menu.show::before {
    border-radius: 100%;
    position: absolute;
    background: black;
    right: -200px;
    left: -200px;
    top: -200px;
    content: '';
    bottom: 0;
  } */

/* Normal Button */

.navItem__button {
  color: #05294f !important;
  font-family: "Assistant-VariableFont_wght";
  font-size: 16pt;
  margin: auto 0.8em auto;
}

.navItem__button:hover {
  transform: scale(1.2);
  transition: all 0.4s ease;
}

/* Button Plan de salud */

.btn.navItem__button--plandesalud {
  color: #004990;
  border: 1px solid #004990 !important;
  font-family: "Assistant-VariableFont_wght";
  border-radius: 30px;
}

.btn.navItem__button--plandesalud:hover {
  color: white;
  font-weight: 800;
  background-color: #004990;
  border: 1px solid #004990 !important;
  font-family: "Assistant-VariableFont_wght";
  border-radius: 30px;
}

/* Button HB Online */

.btn.navItem__button--hb {
  color: #004990;
  border: 1px solid #004990 !important;
  font-family: "Assistant-VariableFont_wght";
  border-radius: 30px;
}

.btn.navItem__button--hb:hover {
  color: white;
  background-color: #004990;
  border: 1px solid #004990 !important;
  font-family: "Assistant-VariableFont_wght";
  border-radius: 30px;
}

.btn.navItem__button--hb:hover .color {
  display: none;
}

.btn.navItem__button--hb .transparent {
  display: none;
}

.btn.navItem__button--hb:hover .transparent {
  display: initial;
}

.saludoUsuarioPS {
  color: #004990;
  font-size: 1.1rem;
  //font-weight: bold;
  font-family: Objectivity-medium;
  margin-top: 5px;
}

/* .contenedorOpcionMenu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex: 1;
} */

a.dropdown-toggle.nav-link {
  color: #004990;
  font-size: 20px;
  font-weight: 600;
}

#dropdownMenu {
  color: #004990;
  font-size: 21px;
  font-family: Objectivity-Light;
  //padding-right: 55px;
  font-weight: 10 !important;
}

hr.dropdown-divider {
  color: #80ff00;
  height: 3px;
}

.opcionMenu {
  color: #2c68a3;
  font-size: 20px;
  font-weight: 600;
}

.opcionMenu2 {
  color: #fff;
  font-size: 19px;
  //font-weight: 400;
  font-weight: 10 !important;
}

.opcionMenuCerrarSesion {
  color: #ff0000;
  font-size: 20px;
  font-weight: 600;
}

.logoPlanSaludMovil {
  background-color: #004990;
}

.logoPlanSaludMovil div {
  border: 0px;
  text-align: center;
  background-color: #004990;
}

.logoPlanSaludMovil div a {
  border-top: 1px solid #e1e9f2;
}

.itemMenuMovil a {
  border-top: 1px solid #e1e9f2;
  width: 95%;
  margin: 0px auto;
  text-align: center;
}

.dropdown-toggle::after {
  display: none;
}
