.campo-perfil-numero {
  display: grid;
  grid-template-columns: 100px auto;
}

#container-date-birth {
  display: grid;
  grid-template-columns: 1fr; //180px auto;
}

@media (max-width: 1290px) {
  .campo-perfil-numero {
    grid-template-columns: 1fr;
  }

  #container-date-birth {
    display: grid;
    grid-template-columns: 1fr;
  }

  .userPSHB .perfil-valor{
    font-size:18px;
  }
}
