h2 {
  margin: 0;
  padding: 0;
}

textarea {
  resize: none;
  width: 80%;
  color: #02366b;
  background-color: white;
  border-radius: 20px;
  border: 3px solid #9fcc4b;
}

.autorizaciones-card {
  // width: 80vw;
  height: 75vh;
  background-color: white;
  border-radius: 10px;
  // border-left: 20px solid red;
  padding: 30px;
  margin: 0px 0px 0px 0px;
  box-shadow: 6px 10px 33px -9px rgba(3, 103, 195, 0.44);
  -webkit-box-shadow: 6px 10px 33px -9px rgba(3, 103, 195, 0.44);
  -moz-box-shadow: 6px 10px 33px -9px rgba(3, 103, 195, 0.44);
}

.autorizaciones-cardbox {
  width: 100%;
  // height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: space-around;
}

.font-color-autorizaciones {
  // font-weight: bold;
  color: rgb(3 50 97);
  text-align: center !important;
  font-family: Objectivity-Regular;
}

.titulo-autorizaciones {
  font-family: "Objectivity-Medium";
}

.autorizaciones-input {
  border-radius: 20px;
  width: 100%;
  // border: 2px solid red;
  min-height: 4.5vh;
  color: rgb(3 50 97);
  padding-left: 2vh;
  padding-right: 2vh;
}

.recupero-contrasena-shadow-blue {
  -webkit-box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
  box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
}

.autorizaciones-input {
  border: 2px solid red;
  width: 100%;
  border-radius: 20px;
  min-height: 4.5vh;
  display: flex;
  align-items: center;
}

.autorizaciones-input i {
  // margin-left: 2vh;
}

.button-continuar-autorizaciones {
  min-width: 150px;
  width: 150px;
}

.autorizaciones-input button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 99%;
  color: rgb(3 50 97);
  font-weight: 500;
}

::-webkit-input-placeholder {
  color: rgb(3 50 97);
  text-align: center;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: rgb(3 50 97);
  text-align: center;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: rgb(3 50 97);
  text-align: center;
}
:-ms-input-placeholder {
  color: rgb(3 50 97);
  text-align: center;
}

.input-file {
  border: 2px solid #9fcc4b;
  width: 100%;
  border-radius: 20px;
  min-height: 4.5vh;
  display: flex;
  align-items: center;
  background-color: #9fcc4b;
  color: white;
}

.input-file i {
  padding-left: 2vh;
}

.input-file button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 99%;
  color: white;
  font-weight: 500;
  margin-left: -20px;
}

.input-file:hover {
  font-weight: 500;
  background: #708e36;
}

.text-area-autorizaciones {
  vertical-align: middle;
  text-align: justify;
  display: table-cell;
}

.text-area-autorizaciones::placeholder {
  font-family: Objectivity-Regular;
  font-size: 1.25rem;
}

.text-area-autorizaciones:focus {
  outline: none !important;
  border-radius: 20px;
  border: 3px solid #9fcc4b;
}

.titulo-comentarios-autorizaciones {
  text-align: center;
  color: rgb(3, 50, 97);
  font-family: "Objectivity-Bold";
  font-size: 20px;
}

.autorizaciones-card hr {
  margin-top: 0px;
  margin-bottom: 0px;
}

#container-autorizaciones-pshb {
  margin-left: 5vw;
  margin-right: 17vw;
}

@media only screen and (max-width: 1000px) {
  .autorizaciones-card {
    // width: 100vw;
    height: inherit;
  }

  textarea {
    width: 100%;
  }

  .cargar-archivos {
    width: 60vw !important;
  }

  .input-file button {
    margin-left: 10px;
  }
}

@media (max-width: 992px) {
  #container-autorizaciones-pshb {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media only screen and (max-width: 550px) {
  .autorizaciones-card {
    // width: 100vw;
    height: inherit;
  }

  .autorizaciones-card i {
    display: none;
  }
  .autorizaciones-input {
    padding-left: 0vh;
    padding-right: 0vh;
  }

  .cargador-autorizaciones {
    width: 100%;
  }
}
