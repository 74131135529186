.section_contacto_form {
  color: #05294f;
  font-family: "Objectivity-Regular";
}

.section_contacto_titulo {
  color: #05294f;
  font-family: "Objectivity-Regular";
}

.section_contacto_titulo-hb {
  font-family: Assistant-Bold;
  font-size: 40px;
  color: #05294f;
}

.section_contacto h4 {
  color: #05294f;
  font-family: "Objectivity-Regular";
  font-weight: 600;
}

// .section_contacto label {
//     color: #05294F;
//     font-family: "Objectivity-Regular";
//     font-size: 19px;
//     font-weight: 600;
// }

.input-title-pshb {
  color: #05294f;
  font-family: "Objectivity-Regular";
  font-size: 19px;
  font-weight: 600;
}

.hr5 {
  border: 2px solid red;
  opacity: 1;
}
.hr6 {
  border: 2px solid #8acb48;
  opacity: 1;
}

.separador-form-comentario {
  border-top: 3px solid #8acb48;
  opacity: 1;
  margin: 1rem 0rem 1.5rem 0rem;
}

select {
  // background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAANCAYAAACtpZ5jAAAABHNCSVQICAgIfAhkiAAAAMJJREFUOE+l08sNwjAQBNAZN0COHOmAQAdUQAvphFACFRDa4AIVQE5IdEEDeHGCIozwD8dXW09j7SxRVqVSWGuNHdrmgTFnsC7NlmpZ1QQ3ItJqwSob71DiRLJ4alnQJC4UeSYxz8YtVAQHfd1X7H8+BnegHfmGc3EP+g3/iwfQXzgVj6BuOIYnoH7YhwOzoVLD9H21/wzP9cJui5a7EFNTzUkMDSd2t6UrUt/T2IKGE1u4WaCjSXozmxlF0xLHonnuXwbzps6EGdpXAAAAAElFTkSuQmCC);
  // background-position: calc(100% - 20px) 12px, calc(100% - 20px) 14px, 100% 0;
  // background-repeat: no-repeat;
  // -webkit-appearance: none;
  // -moz-appearance: none;
}

.section_contacto_form_titulo {
  color: #05294f;
  font-family: "Objectivity-Regular";
  font-size: 35px;
}

.btn.btn_section_contacto {
  color: white;
  background: #05294f;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.section_contacto_form input {
  border: 1px solid #05294f;
}

.section_contacto_form select {
  border: 1px solid #05294f;
}

.section_contacto_form textarea {
  border: 1px solid #05294f;
}

.shadow-blue {
  -webkit-box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
  box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
}

.shadow-external {
  -webkit-box-shadow: 0px 2px 15px -2px rgba(0, 0, 0, 0.28) !important;
  box-shadow: 0px 2px 15px -2px rgba(0, 0, 0, 0.28) !important;
}

.img-card img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.img-contacto {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.categoria_texto {
  color: #05294f;
  font-family: Objectivity-Regular;
  padding-right: auto;
  font-weight: 600;
  font-size: 18px;
}

.boton-contacto {
  font-family: Objectivity-Regular;
  border: 0;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 4px 50px;
  margin-top: 25px;
  margin-right: 10px;
  border-radius: 50px;
  transition: 0.7s;
  color: white;
  background: #05294f;
  text-decoration: none;
  height: 35px;

  &:hover {
    background: white;
    color: #05294f;
    border: 1px solid #5b78aa;
    border-radius: 20px;
    letter-spacing: 1px;
  }
}

.boton-confirmar {
  font-family: Objectivity-Regular;
  border: 0;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 1px;
  display: inline-block;
  //   padding: 4px 50px;
  margin-top: 25px;
  margin-right: 10px;
  border-radius: 50px;
  transition: 0.7s;
  color: white;
  background: #05294f;
  text-decoration: none;
  height: 35px;
  width: 120px;

  //   &:hover {
  //     background: white;
  //     color: #05294f;
  //     border: 1px solid #5b78aa;
  //     border-radius: 20px;
  //     letter-spacing: 1px;
  //   }
}

.boton-volver {
  font-family: Objectivity-Regular;
  border: 1px solid #5b78aa;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 1px;
  display: inline-block;
  //   padding: 4px 50px;
  margin-top: 25px;
  margin-right: 10px;
  border-radius: 50px;
  // transition: 0.7s;
  color: #05294f;
  background: white;
  text-decoration: none;
  height: 35px;
  border-radius: 20px;
  width: 120px;
}

// input[type=number]::-webkit-inner-spin-button,
// input[type=number]::-webkit-outer-spin-button {
//   -webkit-appearance: none;
//   margin: 0;
// }
