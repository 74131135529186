.perfil-PSHB .selected-text{
    font-family: Objectivity-Medium;
}

.perfil-PSHB .custom-select-option
{
    font-family: Objectivity-Medium;
}

.boton-PSHB{
    font-family: Objectivity-Medium;
}

.boton-HB{
    font-family: Assistant-Medium;
}