@use '../../assets/style/colors' as color;

.signInDiv{
    // width: 600px;
    // background-color: red;
    // padding-left: 10px;
    // border-radius: 20px;
}

.inputGoogle{
    border-radius: 20px;
    width: 100%;
    //border: 1px solid red;
    min-height: 4.5vh;
    // color: color.$color-HB-blue-strong;
    // padding-left: 1.5vh;
    // padding-right: 2vh;
    // padding-top: 0.6vh;
    background-color: #1a73e8;
}

.inputGoogle::placeholder {
    font-weight: bold;
    opacity: .7;
    color: color.$color-HB-blue-strong;
}

.inputGoogle:focus{
    outline: none !important;
    border-color: color.$color-HB-blue-strong;
    color: color.$color-HB-blue-light;  
    padding-left: 2vh;
}

@media only screen and (max-width: 1200px){

    .inputGoogle{
        min-height: 6vh;
        width: 100%;
    }

}

@media only screen and (max-width: 800px){

    .inputGoogle{
        min-height: 6vh;
        width: 100%;
    }

}