#h-logout-custom{
    height: calc(100vh - 86px - 33px);
}

#separator-blue-logout
{
    width: 150px;
    border-top: 2px solid #2c68a3;
    margin: 10px 0px;
}

#title-logout-HB{
  color: #2c68a3;
  font-family: "Assistant-SemiBold";
  font-size: 30px;
}

// @media (max-width: 600px){

//   .h-logout-custom{
//     margin-top: 20px;
//   }

//   .logout-footer-pshb{
//     display: none;
//   }
  
// }

.boton-inicio-HB{

    
    color: #fff !important;
    // background-color: #05294F !important;    
    background: linear-gradient(0deg, #0062C1 40%, #034F9A 60%, #02366B 80%,#05294F  100%);  
    border-radius: 20px !important;
    padding: 3px 25px 3px 25px !important;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    height: 35px;
    font-family: "Assistant-SemiBold";
    // font-size: 28px;
    border: 1px solid #0367C3 !important;
      &:hover{
          background-color: white !important;
        //   color:#033261 !important;
      }
}

#logout-footer-hb{
  background-image: url("../../../assets/img/hb/Backgrounds/bg-red-2.png");
  height: 100px;
  width: 100%;
  
}