.grupoMenu{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;        
}
.grupoMenuItemPSHB{        
    padding: 20px 15px 20px 15px;
    border-radius: 10px;
    text-align: center;
    // background: rgb(5,41,79);
    // background:linear-gradient(0deg, #05294F 0%, #004990 40%, #3FB2C9 100%); //linear-gradient(0deg, rgba(5,41,79,1) 0%, rgba(7,85,161,1) 27%, rgba(85,204,227,1) 100%);    
    background: rgb(0,37,72);
    background: linear-gradient(360deg, rgba(0,37,72,1) 0%, rgba(18,119,216,1) 74%);
    display: flex;
    flex-direction: row;
    justify-content:center;    
    align-items: center;
    cursor: pointer;
    height: 80px; 
    margin: 10px 20px 10px 20px;
    width: 230px;
}

.grupoMenuIcono{
    height:30px;
    margin-right: 15px;
   
}

.grupoMenuTexto{
    color: #fff;
    font-size: 22px;    
    line-height: 20px;
    margin-right: 15px;
}

#contenedor-botones-pshb{
    display: flex;
    justify-content:center;
    align-items: center;
    gap: 1rem;
    padding-left:20px;
    padding-bottom:20px;    
}

.itemDown-pshb{
    display: flex;
    // flex-direction: column !important;
    justify-content: end;   
    margin-bottom: -18px; 
    width: 230px;
}

@media (max-width:992px)
{
    #contenedor-botones-pshb{
        justify-content: inherit;
        align-items: inherit;
        gap: inherit;
        padding-left: inherit;
        padding-bottom: inherit;
        padding-right: 40px;
    }

    .grupoMenuItemPSHB{
        width: 100%;
    }

    .itemDown-pshb{
        display: block;        
        justify-content: inherit;   
        margin-bottom: inherit; 
        width: 100%;
    }
    
}
