.grupoMenu{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;        
}
.grupoMenuItem{        
    padding: 20px 5px 20px 5px;
    border-radius: 10px;
    text-align: center;
    // background: rgb(5,41,79);
    // background:linear-gradient(0deg, #05294F 0%, #004990 40%, #3FB2C9 100%); //linear-gradient(0deg, rgba(5,41,79,1) 0%, rgba(7,85,161,1) 27%, rgba(85,204,227,1) 100%);
    background: rgb(5,41,79);
    background: linear-gradient(360deg, rgba(5,41,79,1) 0%, rgba(0,73,144,1) 22%, rgba(63,178,201,1) 100%);
    display: flex;
    flex-direction: row;
    justify-content:center;    
    align-items: center;
    cursor: pointer;
    height: 80px; 
    margin: 10px 20px 10px 20px;
    
}

.grupoMenuIcono{
    height:50px;
    // height: 3em;
    margin-right: 15px;
   
}

.grupoMenuTexto{
    color: #fff;
    font-size: 22px;    
    // font-size: 1em;
    line-height: 20px;
    margin-right: 0px;
    //margin-right:15px; --> Facturas
}

.icono-atencion
{
    padding-left: 20px;
}

@media (min-width: 768px) and (max-width:1024px)
{
    .grupoMenuTexto
    {
        font-size: 15px;
    }

    .icono-atencion
    {
        padding-left: 10px;
    }    

    .grupoMenuIcono
    {
        margin-right: 8px;
    }
}

a{
    text-decoration: none;
}

.icononoNovedades{
    margin-bottom: 3px;
    padding-left: 5px;
}

.itemDown{
    display: flex;
    // flex-direction: column !important;
    justify-content: end;   
    margin-bottom: -18px; 
    width: 230px;
}

.itemDownHB{
    display: flex !important;
    // flex-direction: column !important;
    justify-content: end !important;   
    margin-bottom: -18px;         
}


// .botones-menu{
//     display: grid;
//     // gap:0.5rem;
//     // grid-auto-rows: 22rem;
//     grid-template-columns: 1fr repeat(4,minmax(min(100%,250px),1fr)) 1fr;
//     grid-auto-flow: dense;
//     align-items: center;
//     column-gap: 0.5rem;
// }

// @media (min-width:992px){
//     .contenedor-botones{

//     }
// }