.input-editing{
    background: #034787;
    border: none;
    color: white;
    outline: none;
}

.form-HB{

    font-family: Assistant-Medium;

}

.form-PSHB{

    font-family: Objectivity-Medium;
}

.campo-perfil-email{
    display: grid;
    grid-template-columns:1fr; //45px 1fr;
}

@media (max-width: 1290px)
{
    .campo-perfil-email{
        grid-template-columns: 1fr;
    }
}

.disabled-btn-contacto {
    opacity: 0.5;
    pointer-events: none;
}