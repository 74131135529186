

.footer-pshb-logout{
    background-image: url("../../../assets/img/pshb/Backgrounds/bg-green.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: bottom;
  height: 105px;
  width: 100%;
  position: absolute;
  bottom: 0;
}



.logout{
    z-index: 1000;
    // border: 1px solid red;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    height: 100vh;
}