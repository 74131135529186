.asideWebHB{
    
    //min-height: 100vh;
    width: 18vw;//16vw ;
    min-width: 18vw;
    background: rgb(2,54,107);
    background: linear-gradient(360deg, rgba(2,54,107,1) 0%, rgba(3,79,154,1) 40%, rgba(60,206,235,1) 100%);//linear-gradient(0deg, #05294F 20%, #02366B 40%, #034F9A 60%, #0062C1 80%, #3CCEEB 100%);      
    display: flex;
    padding-top: 40px;    
    font-family: Assistant-SemiBold;
    max-width: 220px;
    padding-left: 10px;
    padding-right: 10px;
}
.asideWebPSHB{
    
    //min-height: 100vh;
    width: 18vw ;
    min-width: 18vw;
    //background: linear-gradient(0deg, #05294F 20%, #02366B 40%, #034F9A 60%, #0062C1 80%, #3CCEEB 100%);      
    background: rgb(2,54,107);
    background: linear-gradient(360deg, rgba(2,54,107,1) 0%, rgba(3,79,154,1) 40%, rgba(60,206,235,1) 100%);//linear-gradient(0deg, #05294F 20%, #02366B 40%, #034F9A 60%, #0062C1 80%, #3CCEEB 100%);      
    display: flex;
    padding-top: 40px;    
    font-family: Objectivity-Regular;
    max-width: 220px;
    padding-left: 10px;
    padding-right: 10px;

}

.asideWebPSHB .navigate-button
{
    padding: 7px 0px 5px 0px
}

.asideWebHB .navigate-button{
    padding: 4px 0px 7px 0px
}


.asideMobile{
    display: none !important;
}

.separador{
    border-top:1px solid #c5d8eb;
    min-height: 1px;
    width: 50%;
    margin-top: 20px;
    margin-bottom: 10px;
    align-self: center;
  }
  

.circleSmall{
    display: none !important;
}

.navigate-button{
	color: inherit;
	border: none;
	// padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
    color: white;
    //font-weight: bold;
    //font-family: Assistant-Bold ;
}

.navigate-button:hover{
    color: rgb(209, 209, 209);
}

.active{
    color: #02366B;
    background-color: white;
    border-radius: 20px;
    
}


.active:hover{
    color: #285c8f;
    background-color: rgb(209, 209, 209);
}


@media only screen and (max-width: 1000px){

    .asideWebHB{
        display: none !important;
    }
    .asideWebPSHB{
        display: none !important;
    }

    .asideMobile{
        display: block !important;
        width: 10vw;
        background: linear-gradient(0deg, #05294F 20%, #02366B 40%, #034F9A 60%, #0062C1 80%, #3CCEEB 100%);  
        position: relative;
        // min-height: 100vh;
    }

    .showAsideMobile{
        width: 50vw;
        background: linear-gradient(0deg, #05294F 20%, #02366B 40%, #034F9A 60%, #0062C1 80%, #3CCEEB 100%);  
        position: absolute;
        z-index: 1000;
        // min-height: 100vh;
    }




}