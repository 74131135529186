.footer-green-pshb{
    background-image: url("../../../assets/img/pshb/Backgrounds/bg-green.png");
    object-fit: cover;    
    height: 100px;
    width: 100%;    
  }

  .footer-pshb-gf{
    background-image: url("../../../assets/img/pshb/Backgrounds/bg-green.png");
    height: 100px;
    width: 100%;
    //background-repeat: no-repeat;
    //background-size: contain;
    // background-position-y: bottom;   
  }