.simple-header{
    padding-top: 30px;    
    padding-left: 50px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;

    h2{
        text-align: center;
        padding-bottom: 10px;
    }
}

.custom-container{
   padding:30px 50px 0px 50px;
}

.custom-footer{
    padding: 40px 0px 0px 0px;    
    margin-bottom: -50px;
}

.img-footer-modal-pshb{
    background-image: url("../../../../assets/img/pshb/Backgrounds/modal-green-round.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: bottom;
    width: 100%;
    height: 80px;
}

.custom-modal-body
{
    padding:0px !important;
}

.error-data{
    border:2px red solid !important;
}