#titulo-error{
  
  font-size: 40px;
  color:#004890;
}

#subTitulo-error{    
    font-size: 30px;
    color:#004890;
}

#page-error-pshb #titulo-error{
  font-family: "Objectivity-Bold";
}

#page-error-pshb #subTitulo-error{
  font-family: "Objectivity-Regular";
}


#page-error-hb #titulo-error{
  font-family: "Assistant-Bold";
}

#page-error-hb #subTitulo-error{
  font-family: "Assistant-Regular";
}
