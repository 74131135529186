@use '../../../assets/style/colors' as color;

.option-register{
    border-radius: 20px;
    width: 50%;
    border: 1px solid color.$color-HB-blue-strong;
    min-height: 4.5vh;
    color: color.$color-HB-blue-strong;
    padding-left: 1.5vh;
    padding-right: 1vh;
    font-weight: bold;
    opacity: .7;
    color: color.$color-HB-blue-strong;
    background-color: white;
}

.shadow-blue{
    -webkit-box-shadow: 0px 10px 11px -8px rgba(0,73,144,0.2) !important;
    box-shadow: 0px 10px 11px -8px rgba(0,73,144,0.2) !important;
}

