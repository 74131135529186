@use '../../../assets/style/colors' as color;

$blueColor: rgb(3, 50, 97);
$redColor: rgb(255, 23, 23);

#headerContent {
  display: flex;
  justify-content: space-between;
}

#sideRight {
  display: flex;
  align-items: center;
}

#titleStudy {
  max-width: 200px;
  width: 100%;
  margin-right: 15px;
  font-family: "Assistant-SemiBold";
}

// .shadow-blue {
//     -webkit-box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
//     box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
// }

// .shadow-red {
//     -webkit-box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
//     box-shadow: 0px 10px 11px -8px rgba(0, 73, 144, 0.2) !important;
// }

.input-family{
  border-radius: 20px;
  width: 50%;
  border: 1px solid color.$color-HB-blue-light;
  min-height: 4.5vh;
  color: color.$color-HB-blue-strong;
  padding-left: 2vh;
  padding-right: 2vh;
}

.input-family::placeholder {
  font-weight: bold;
  opacity: .7;
  color: color.$color-HB-blue-strong;
}

.input-family:focus{
  outline: none !important;
  border-color: color.$color-HB-blue-strong;
  color: color.$color-HB-blue-light;  
  padding-left: 2vh;
}

.input-family-error{
  border-radius: 20px;
  width: 50%;
  border: 1px solid red;
  min-height: 4.5vh;
  color: red;
  padding-left: 2vh;
  padding-right: 2vh;
}

.input-family-error::placeholder {
  font-weight: bold;
  opacity: .7;
  color: red;
}


.input-family-error:focus{
  outline: none !important;
  border-color: red;
  color: red;  
  padding-left: 2vh;
}

#user {
  width: 100vw;
  max-width: 300px;
  background-color: $blueColor;
}

#user option {
  background-color: $blueColor;
}

.input-icons i {
  position: absolute;
}

.input-icons {
  margin-bottom: 10px;
}

.icon {
  padding: 10px;
  color: green;
  min-width: 50px;
}

.input-field {
  padding: 2px 35px;
  border-radius: 20px;
  border: 1px solid #224d7c;
  background-color: $blueColor;
  color: white;
  font-family: "Assistant-Regular";
}

.input-field:focus {
  outline: none;
}

.i-25 {
  width: 25px;
  margin-right: 5px;
  cursor: pointer;
}

.customIcon {
  transform: translate(100%, 50%);
  color: rgb(1, 101, 144);
  position: absolute;
}

#estudio {
  background-color: $redColor;
  border-color: $redColor;
  color: white;
}

#findUserName {
  display: flex;
}

#filterServices {
  max-width: 400px;
  width: 100%;
}

// .separador-result{
//     border-top: 3px solid rgb(171 195 219);
//     margin-bottom: 10px;
// }

.col-years {
  display: flex;
  justify-content: end;
}

#paginate {
  display: flex;
  justify-content: end;
}

#tableResults {
  color: #033261;
}

.section_pshb_titulo {
  color: #05294f;
  font-family: "Objectivity-Medium";
  font-size: 30px;
}

.information-circle {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.information-triangle {
  width: 0;
  height: 0;
  border-left: 25px solid #f0ad4e;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  // transform: translateX(-30px);
}

#header-invoice {
  display: flex;
  justify-content: space-between;
}

#icon-information {
  height: 40px;
  width: auto;
  cursor: pointer;
}

#header-add-family-title {
  display: flex;
  flex-direction: column;
}

.header-add-family {
  height: 120px;
  border-bottom: 2px solid red;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#header-add-family-separator {
  border-top: 1px solid red;
  min-height: 1px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.family-add-buttons {
  display: flex;
  justify-content: center;
}

// .hb-title
// {
//     color:rgb(20,54,90);
//     font-family: "Assistant-SemiBold";
//     font-size: 30px;
// }

// .hb-subtitle
// {
//     color:rgb(20,54,90);
//     font-family: "Assistant-Medium";
//     font-size: 20px;
//     line-height: 25px;
// }

.hb-label {
  color: rgb(20, 54, 90);
  font-family: "Assistant-Regular";
  font-size: 18px;
  line-height: 25px;
}


.hb-label-error {
  color: red;
  font-family: "Assistant-Regular";
  font-size: 18px;
  line-height: 25px;
}

#family-add-container {
  padding: 50px 25px;
}

.body-add-family {
  height: 120px;
}

.w-100 {
  width: 100%;
}

.opacity {
  opacity: 0.2;
}

.customIcon-family-group {
  // transform: translate(100%,50%);
  background-color: rgb(1, 101, 144);
  position: absolute;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 1px solid #6286ac;
  min-height: 4.5vh;
  padding-left: 2vh;
  padding-right: 2vh;
  color: white;
}

.customIcon-family-group span {
  margin-left: 5px;
}

.customIcon-family-group input {
  padding-left: 110px;
}

section {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: normal;
  justify-content: space-between;
}

.form-gf-hb {
  padding-right: 30px;
  padding-left: 30px;
}

.form.gf {
  margin-top: 50px;
}

@media only screen and (max-width: 1000px) {
  .form-gf-hb {
    padding-right: 10px;
    padding-left: 10px;
  }
}

@media only screen and (max-width: 800px) {
  // .form-gf-hb {
  // margin-top: 120px;
  // }

  .header-add-family {
    padding-bottom: 100px;
  }

  .form.gf {
    margin-top: 150px;
  }
}

@media only screen and (max-width: 500px) {
  .header-add-family {
    padding-bottom: 140px;
  }
}

.header-grupo-familiar {
  flex-direction: row;
}

@media (max-width: 992px) {
  .header-grupo-familiar {
    flex-direction: column;
  }

  .header-grupo-familiar .btn {
    margin-left: 0px;
    margin-top: 4px;
  }

  .title_mobile {
    padding-bottom: 15px !important;
  }

  .information_mobile {
    margin-left: auto;
  }

  .agregar_mobile {
    display: flex;
    justify-content: space-between;
    padding-top: 6px;
  }

  .separador-table-red-result-mobile {
    margin-top: 35px !important;
  }
}

// CARGAR ARCHIVO
.custom-load-icon {
  // transform: translate(100%,50%);
  background-color: #05294f;
  position: absolute;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 1px solid #6286ac;
  min-height: 4.5vh;
  padding-left: 2vh;
  padding-right: 2vh;
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: 4px;
}

.custom-load-icon span {
  margin-left: 20px;
}

.custom-load-icon input {
  padding-left: 110px;
}

.input-icons i {
  position: absolute;
}

.input-icons {
  margin-bottom: 10px;
}

.error-file {
  color: red !important;
}

.round-label {
  border-radius: 20px;
  width: 50%;
  border: 1px solid #6286ac;
  min-height: 4.5vh;
  color: #224d7c;
  padding-left: 2vh;
  padding-right: 2vh;
  padding-bottom: 4px;
}
// FIN CARGAR ARCHIVO

@media only screen and (max-width: 1200px) {
  .round-label {
    min-height: 6vh;
  }

  .custom-load-icon {
    // transform: translate(100%,50%);
    min-height: 6vh;
  }
}

// Responsive for table

@media only screen and (max-width: 1750px) {
  .table-hb thead tr {
    text-align: inherit;
    font-size: 20px;
  }
  .table-hb tbody tr {
    text-align: inherit;
    font-size: 20px;
  }
}

@media only screen and (max-width: 1000px) {
  .table-hb thead tr {
    text-align: inherit;
    font-size: 20px;
    line-height: normal;
  }
  .table-hb tbody tr {
    text-align: inherit;
    font-size: 18px;
  }
}
