.recupero-contrasena {
  width: 79vw;
}

@media only screen and (max-width: 1000px) {
  .recupero-contrasena {
    width: 100vw;
  }

  .recupero-contrasena-card {
    width: 100vw;
    // height: 70vh;
  }

  .introducion-cambio-contraseña-HB {
    font-size: 25px;
  }

  .texto-cambio-contraseña-HB {
    font-size: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .introducion-cambio-contraseña-HB {
    font-size: 25px;
  }

  .texto-cambio-contraseña-HB {
    font-size: 20px;
  }
}

@media only screen and (max-width: 550px) {
  .introducion-cambio-contraseña-HB {
    font-size: 25px;
  }

  .texto-cambio-contraseña-HB {
    font-size: 20px;
  }
}

@media only screen and (max-width: 465px) {
  .introducion-cambio-contraseña-HB {
    font-size: 23px;
  }

  .texto-cambio-contraseña-HB {
    font-size: 18px;
  }
}
