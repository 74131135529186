@use '../../../assets/style/colors' as color;

.footer-hb-gf{
    background-image: url("../../../assets/img/hb/Backgrounds/bg-red-2.png");
    height: 100px;
    width: 100%;
    //background-repeat: no-repeat;
    //background-size: contain;
    // background-position-y: bottom;   
  }

.option-register{
    border-radius: 20px;
    width: 50%;
    border: 1px solid color.$color-HB-blue-strong;
    min-height: 4.5vh;
    color: color.$color-HB-blue-strong;
    padding-left: 1.5vh;
    padding-right: 1vh;
    font-weight: bold;
    opacity: .7;
    color: color.$color-HB-blue-strong;
    background-color: white;
}

.shadow-blue{
    -webkit-box-shadow: 0px 10px 11px -8px rgba(0,73,144,0.2) !important;
    box-shadow: 0px 10px 11px -8px rgba(0,73,144,0.2) !important;
}

.family-group-pshb{
    width: 100%;
    padding-left: 0px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between!important;
    overflow-x: hidden;
    //padding-right: 50px;
    //display: flex;
    

}
     .tester {
     flex-direction: column;

    
     }

     


@media only screen and (max-width: 1000px){
    .family-group-pshb{
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }

}
